import React, { useEffect, useState } from "react";

const Footer = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let backToTopClasses =
    "back-to-top d-flex align-items-center justify-content-center";
  if (scrolled) {
    backToTopClasses += " active";
  }
  return (
    <>
      <footer id="footer" className="footer" style={{ height: "0rem" }}>
        <div className="footer-top">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-5 col-md-12 footer-info">
                <a href="index.html" className="logo d-flex align-items-center">
                  <img src={process.env.PUBLIC_URL + "/img/logo.png"} alt="" />

                  <span>Hikari Type</span>
                </a>
                <p>
                  印刷のことならお任せ下さい
                  ヒカリ印刷では、名刺やブローシュアはじめとする紙製品、
                  ディスプレー、名入れ商品まで広範囲に取り扱っております。
                </p>
              </div>

              <div className="col-lg-2 col-6 footer-links">
                <ul></ul>
              </div>

              <div className="col-lg-2 col-6 footer-links"></div>
              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>
                  製造拠点：ニューヨーク、テキサス、
                  <br />
                  カリフォルニア
                  <br />
                  <br />
                  <strong>Phone:</strong> (800) 594-0210
                  <br />
                  <strong>Email:</strong> order@hikaritype.com
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" className={backToTopClasses} role="button">
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};

export default Footer;
