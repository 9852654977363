import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  Row,
  Select,
  Card,
  Modal,
} from "antd";
import { useState } from "react";
import { auth, db } from "../../firebase/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  setDoc,
  doc,
  query,
  where,
  getDocs,
  collection,
} from "firebase/firestore";
import css from "./Register.module.css";
import Agreement from "./Agreement";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Register = () => {
  const [form] = Form.useForm();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [username, setUsername] = useState("");
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const validateUsername = async (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    const exists = await usernameExists(value);
    if (exists) {
      return Promise.reject(new Error("Username already exists!"));
    }

    return Promise.resolve();
  };

  const validateEmail = async (_, value) => {
    if (!value) {
      // If the value is empty, return a resolved promise without any message.
      return Promise.resolve();
    }

    const exists = await emailExists(value);
    if (exists) {
      return Promise.reject(new Error("Email already exists!"));
    }

    return Promise.resolve();
  };
  const register = async (e) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const docRef = doc(db, "users", userCredential.user.uid);

      await setDoc(docRef, {
        username: username,
        email: email,
        // userID: userCredential.user.uid,
      });

      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  const usernameExists = async (desiredUsername) => {
    const usernameRef = collection(db, "users");
    const q = query(usernameRef, where("username", "==", desiredUsername));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty; // If the snapshot is not empty, the username exists
  };

  const emailExists = async (desiredEmail) => {
    const usernameRef = collection(db, "users");
    const q = query(usernameRef, where("email", "==", desiredEmail));
    console.log({ q });
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // If the snapshot is not empty, the username exists
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        defaultValue="1"
        style={{
          width: 70,
        }}
      >
        <Option value="1">+1</Option>
        <Option value="81">+81</Option>
      </Select>
    </Form.Item>
  );

  return (
    <section className="hero">
      <div className={css.orderForm}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={register}
          style={{
            width: "650px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
          scrollToFirstError
        >
          <Card
            title="Registration"
            bordered={true}
            className={css.antdCardStyle}
            headStyle={{ backgroundColor: "#f5f5f5", paddingLeft: "16rem" }}
            bodyStyle={{ paddingRight: "90px" }}
          >
            <Form.Item
              name="companyName"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: "Please input your company name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="userName"
              label="User Name"
              rules={[
                {
                  required: true,
                  message: "Please input your username",
                },
                {
                  validator: validateUsername,
                  message: "Username already exists!",
                },
              ]}
              onChange={async (e) => {
                setUsername(e.target.value);
                const exists = await usernameExists(e.target.value);
                if (exists) {
                  console.log("Username already exists");
                } else {
                  console.log("Username is available");
                }
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
                {
                  validator: validateEmail,
                  message: "Email already exists!",
                },
              ]}
              onChange={async (e) => {
                setEmail(e.target.value);
                const exists = await emailExists(e.target.value);
                if (exists) {
                  console.log("Email already exists");
                } else {
                  console.log("Email is available");
                }
              }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },
              ]}
              hasFeedback
              onChange={(e) => setPassword(e.target.value)}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number",
                },
              ]}
            >
              <Input
                addonBefore={prefixSelector}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error("Should accept agreement")),
                },
              ]}
              {...tailFormItemLayout}
            >
              <div>
                <Checkbox>
                  I have read the{" "}
                  <a href="/#" onClick={showModal}>
                    agreement
                  </a>
                </Checkbox>

                <Modal
                  title="Agreement"
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Agreement />
                </Modal>
              </div>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#4458E8", borderColor: "#4458E8" }}
              >
                Register
              </Button>
            </Form.Item>
          </Card>
        </Form>
      </div>
    </section>
  );
};
export default Register;
