import React, { useEffect } from "react";
import AppHeader from "../AppHeader/AppHeader";
import Footer from "../Footer/Footer";
import AOS from "aos";

const LandingPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <AppHeader />
      <section className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">ヒカリ 印刷</h1>
              <h2 data-aos="fade-up" data-aos-delay="400">
                紙幣以外は何でもプリントします。
              </h2>
              <div data-aos="fade-up" data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    href="/orderForm"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get Started</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img
                src={process.env.PUBLIC_URL + "/img/hero-img.png"}
                className="img-fluid"
                alt=""
              />{" "}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default LandingPage;
