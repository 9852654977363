import React, { useEffect, useState } from "react";
import { auth } from "../../firebase/firebase";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { Link } from "react-router-dom";

const AppHeader = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isToggled, setIsToggled] = useState(false);

  const handleToggleClick = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let headerClasses = "header fixed-top";
  if (scrolled) {
    headerClasses += " header-scrolled";
  }
  return (
    <header id="header" className={headerClasses}>
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center">
          <img src={process.env.PUBLIC_URL + "/img/logo.png"} alt="" />
          <span>Hikari Type</span>
        </a>
        <nav
          id="navbar"
          className={` navbar ${isToggled ? "navbar-mobile" : " "}`}
        >
          <ul>
            <li>
              <Link className="nav-link scrollto active" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link className="nav-link scrollto active" to="/orderForm">
                Order Now
              </Link>
            </li>

            <li>
              <Link to="/orderHistory">Order History</Link>
            </li>

            <li>
              {isLoggedIn ? (
                <a
                  className="getstarted scrollto"
                  style={{ cursor: "pointer" }}
                  onClick={() => signOut(auth)}
                  href="/"
                  role="button"
                  tabIndex="0"
                >
                  Sign out
                </a>
              ) : (
                <Link to="/login" className="getstarted scrollto">
                  Login
                </Link>
              )}
            </li>
          </ul>
          <i
            className={`bi ${isToggled ? "bi-x" : "bi-list"} mobile-nav-toggle`}
            onClick={handleToggleClick}
          ></i>{" "}
        </nav>
      </div>
    </header>
  );
};

export default AppHeader;
