import React, { useState, useEffect } from "react";
import { Card, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import css from "./OrderForm.module.css";

const UploadFile = ({ formSubmitted, onImageChange }) => {
  const [imageStored, setImageStored] = useState(null);

  useEffect(() => {
    if (formSubmitted) {
    }
  }, [formSubmitted]);

  return (
    <Card title="Upload Files" bordered={true} className={css.antdCardStyle}>
      <Upload.Dragger
        type="file"
        listType="picture"
        maxCount="10"
        progress="line"
        beforeUpload={(file) => {
          return false;
        }}
        onChange={(event) => {
          setImageStored(event.fileList);
          onImageChange(event.fileList);
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </Upload.Dragger>
    </Card>
  );
};

export default UploadFile;
