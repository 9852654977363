import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Spin,
  Card,
  DatePicker,
  InputNumber,
  message,
} from "antd";
import { db, auth } from "../../firebase/firebase";
import css from "./OrderForm.module.css";
import AppHeader from "../AppHeader/AppHeader";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";

import axios from "axios";

import {
  getFirestore,
  addDoc,
  collection,
  serverTimestamp,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { storage } from "../../firebase/firebase";
import UploadFile from "./UploadFile";
import { ref, uploadBytesResumable } from "firebase/storage";

const { Option } = Select;
const { Item } = Form;

const OrderForm = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [companyAddresses, setCompanyAddresses] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [companyReceiver, setCompanyReceiver] = useState(null);
  const [receiverName, setReceiverName] = useState({
    receiverFirstName: "",
    receiverLastName: "",
  });
  const [parentImageStored, setParentImageStored] = useState(null);

  const formRef = React.useRef(null);
  const onAddressChange = (value) => {
    let info = companyAddresses[value];

    formRef.current?.setFieldsValue({
      shippingStreetAddress: `${info.streetAddress}`,
      shippingSecondAddress: `${info.addressLine2}`,
      shippingCity: `${info.city}`,
      shippingState: `${info.state}`,
      shippingZip: `${info.zipCode}`,
    });
  };

  const onReceiverChange = (value) => {
    let info = companyReceiver[value];

    formRef.current?.setFieldsValue({
      receiverName: `${info.receiverFirstName} ${info.receiverLastName}`,
    });
  };

  const navigate = useNavigate();

  const handleAddressChange = (value) => {
    let selectId = companyAddresses[value].companyId;

    const selected = companyAddresses.find(
      (address) => address.companyId === selectId
    );
    setSelectedAddress(selected);
  };

  const handleImageChange = (fileList) => {
    setParentImageStored(fileList);
  };

  const onFinish = async (values) => {
    setFormSubmitted(true);
    const db = getFirestore();

    if (values.deadlineDate !== undefined) {
      const dateObject = new Date(values.deadlineDate.$d);

      values.deadlineDate = dateObject.toString();
    }

    for (let key in values) {
      if (values[key] === undefined) {
        delete values[key];
      }
    }

    // emailSubmit(values, parentImageStored);

    try {
      const docRef = await addDoc(collection(db, "orders"), {
        ...values,
        timestamp: serverTimestamp(),
        companyId: companyId,
      });
      setOrderId(docRef.id);

      values.orderID = docRef.id;

      emailSubmit(values, parentImageStored);

      if (parentImageStored) {
        console.log("parent image", parentImageStored);
        uploadFile(parentImageStored, companyId, docRef.id);
      }

      message.success("Your order has been submitted successfully!");

      navigate("/orderHistory");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const uploadFile = (fileWrappers, companyId, orderId) => {
    console.log("order Id", orderId);

    fileWrappers.forEach((fileWrapper) => {
      const file = fileWrapper.originFileObj;
      const fileName = fileWrapper.name;
      const path = `companies/${companyId}/orders/${orderId}/orderImage/${fileName}`;
      const fileRef = ref(storage, path);

      uploadBytesResumable(fileRef, file)
        .then(() => {
          console.log(`Upload complete for file: ${fileName}`);
        })
        .catch((error) => {
          console.error("Upload error for file " + fileName + ":", error);
        });
    });
  };

  const emailSubmit = async (values, file) => {
    const formData = new FormData();

    if (file && file[0].thumbUrl) {
      file = file[0];

      const base64 = file.thumbUrl.split(",")[1];

      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: file.type });

      formData.append("file", blob, file.name);
    }

    formData.append("values", JSON.stringify(values));

    try {
      const response = await axios.post(
        "http://api.whitechrome.io/submitOrder",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert(response.data.message);
    } catch (error) {
      console.error("Failed to send message:", error);
      alert("Failed to send message.");
    }
  };

  const getUserInfo = async () => {
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const companyId = docSnap.data().companyId;
      setCompanyId(companyId);
    } else {
      console.log("No such document!");
    }
  };

  const fetchCompanyAddresses = useCallback(async () => {
    const companyAddressesQuery = query(
      collection(db, "company-addresses"),
      where("companyId", "==", companyId)
    );

    const companyReceiversQuery = query(
      collection(db, "company-receiver"),
      where("companyId", "==", companyId)
    );

    const querySnapshotAddresses = await getDocs(companyAddressesQuery);
    const querySnapshotReceivers = await getDocs(companyReceiversQuery);

    const addresses = querySnapshotAddresses.docs.map((doc) => doc.data());
    const receivers = querySnapshotReceivers.docs.map((doc) => doc.data());

    setCompanyAddresses(addresses);
    setCompanyReceiver(receivers);
  }, [companyId, setCompanyAddresses, setCompanyReceiver]);

  useEffect(() => {
    const authObserver = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return authObserver;
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (user) {
      getUserInfo();
      fetchCompanyAddresses();
    }
  }, [user, fetchCompanyAddresses]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (companyId) {
      fetchCompanyAddresses();
    }
  }, [companyId, fetchCompanyAddresses]);

  if (loading) {
    return (
      <div className={css.spinScreen}>
        <Spin size="large" />
      </div>
    );
  }

  if (!user) {
    navigate("/login");
    return null;
  }

  return (
    <>
      <AppHeader />
      <div className={css.orderForm}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          ref={formRef}
          style={{
            width: "700px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Card
            title="Business Card Information"
            bordered={true}
            className={css.antdCardStyle}
            headStyle={{ backgroundColor: "#f5f5f5" }}
            bodyStyle={{ padding: "16px" }}
          >
            <div className={css.formDoubleLine}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: false,
                    message: "Please input your first name.",
                  },
                ]}
              >
                <Input placeholder="First name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: false,
                    message: "Please input your last name.",
                  },
                ]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
              <Form.Item
                name="japaneseName"
                label="Name in Japanese"
                rules={[
                  {
                    required: false,
                    message: "Please input your name in Japanese.",
                  },
                ]}
              >
                <Input placeholder="Japanese name" />
              </Form.Item>
              <Form.Item></Form.Item>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: false,
                    message: "Please input your name in Japanese.",
                  },
                ]}
              >
                <Input placeholder="Title" />
              </Form.Item>
              <Form.Item
                name="division"
                label="Division"
                rules={[
                  {
                    required: false,
                    message: "Please input your name in Japanese.",
                  },
                ]}
              >
                <Input placeholder="Division" />
              </Form.Item>
              <Form.Item
                name="department"
                label="Department"
                rules={[
                  {
                    required: false,
                    message: "Please input your department.",
                  },
                ]}
              >
                <Input placeholder="Department" />
              </Form.Item>

              <Form.Item
                name="companyName"
                label="Company Name"
                rules={[
                  {
                    required: false,
                    message: "Please input your Company name.",
                  },
                ]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>
            </div>
          </Card>
          <Card
            title="Address Information"
            bordered={true}
            className={css.antdCardStyle}
            headStyle={{ backgroundColor: "#f5f5f5" }}
          >
            <div className={css.formAddress}>
              <Form.Item
                name="streetAddress"
                label="Street Address"
                rules={[
                  {
                    required: false,
                    message: "Please input your street address.",
                  },
                ]}
              >
                <Input placeholder="Street Address" />
              </Form.Item>
              <Form.Item
                name="secondAddress"
                label="Address Line 2"
                rules={[
                  {
                    required: false,
                    message: "Please input your name in Japanese.",
                  },
                ]}
              >
                <Input placeholder="Suite/Apt" />
              </Form.Item>
              <div className={css.formDoubleLine}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: false,
                      message: "Please input your name in Japanese.",
                    },
                  ]}
                >
                  <Input placeholder="City" />
                </Form.Item>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: false,
                      message: "Please input your state.",
                    },
                  ]}
                >
                  <Input placeholder="State" />
                </Form.Item>
                <Form.Item
                  name="zip"
                  label="Zip / Postcode"
                  rules={[
                    {
                      required: false,
                      message: "Please input your zip or postalcode.",
                    },
                  ]}
                >
                  <Input placeholder="Zip / Postcode" />
                </Form.Item>
              </div>
            </div>
            <Form.Item
              name="notes"
              label="Notes"
              rules={[
                {
                  required: false,
                  message: "Please input your description!",
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="Notes" />
            </Form.Item>
          </Card>

          <Card
            title="Order Information"
            bordered={true}
            className={css.antdCardStyle}
            headStyle={{ backgroundColor: "#f5f5f5" }}
          >
            <Form.Item
              name="orderNumber"
              label="Purchaser's Order Number"
              rules={[
                {
                  required: false,
                  message: "Please input your purchase order.",
                },
              ]}
            >
              <Input placeholder="Purchase Order" />
            </Form.Item>
            <Form.Item
              name="purchaserInfo"
              label="Purchaser's Info"
              rules={[
                {
                  required: false,
                  message: "Please input your phone number.",
                },
              ]}
            >
              <Input placeholder="Purchaser's Info" />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[
                {
                  required: false,
                  message: "Please input your phone number.",
                },
              ]}
            >
              <Input placeholder="Phone number" />
            </Form.Item>

            <Form.Item layout="vertical" onFinish={onFinish}>
              <Item
                name="deadlineDate"
                label="Deadline Date"
                rules={[
                  {
                    type: "object",
                    required: false,
                    message: "Please select a date!",
                  },
                ]}
              >
                <DatePicker
                  style={{
                    width: "250px",
                    borderRadius: "5px",
                  }}
                />
              </Item>
            </Form.Item>
            <div className={css.formDoubleLine}>
              <Form.Item
                name="quantity"
                label="Quantity"
                rules={[
                  {
                    type: "number",
                    min: 0, // Minimum value
                    max: 20000, // Maximum value
                    required: false,
                    message: "Please input quantity.",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Quantity"
                  style={{
                    width: "250px",
                  }}
                />
              </Form.Item>

              <Form.Item
                name="shippingMethod"
                label="Shipping method"
                rules={[
                  {
                    required: false,
                    message: "Please select a shipping method.",
                  },
                ]}
              >
                <Select placeholder="Select a shipping method">
                  <Option value="upsGround">UPS Ground</Option>
                  <Option value="2ndDayAir">2nd Day Air</Option>
                  <Option value="nextDayAir">Next Day Air</Option>
                </Select>
              </Form.Item>
            </div>
          </Card>
          {/* <Form ref={formRef} name="control-ref"> */}
          <Form.Item name="control-ref">
            <Card
              title="Shipping Information"
              bordered={true}
              className={css.antdCardStyle}
              headStyle={{ backgroundColor: "#f5f5f5" }}
              ref={formRef}
              name="control-ref"
            >
              <Form.Item
                name="shippingAddress"
                label="Shipping Addresses"
                className={css.shippingSelect}
              >
                <Select
                  placeholder="Please select a shipping method"
                  onChange={onAddressChange}
                  allowClear
                >
                  {companyAddresses &&
                    companyAddresses.length > 0 &&
                    companyAddresses.map((address, index) => (
                      <Option key={index} value={address.id}>
                        {address.streetAddress}, {address.city},{" "}
                        {address.zipCode} {address.state}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <div className={css.formAddress}>
                <Form.Item
                  name="shippingStreetAddress"
                  label="Street Address"
                  rules={[
                    {
                      required: false,
                      message: "Please input your address.",
                    },
                  ]}
                  className={css.inputAddress}
                >
                  <Input
                    placeholder="Street Address"
                    // defaultValue={selectedAddress?.streetAddress || ""}
                  ></Input>
                </Form.Item>
                <Form.Item
                  name="shippingSecondAddress"
                  label="Address Line 2"
                  rules={[
                    {
                      required: false,
                      message: "Please input your name in Japanese.",
                    },
                  ]}
                  className={css.inputAddress}
                >
                  <Input placeholder="Suite/Apt" />
                </Form.Item>
                <div className={css.formDoubleLine}>
                  <Form.Item
                    name="shippingCity"
                    label="City"
                    rules={[
                      {
                        required: false,
                        message: "Please input your city.",
                      },
                    ]}
                    className={css.inputAddress}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                  <Form.Item
                    name="shippingState"
                    label="State"
                    rules={[
                      {
                        required: false,
                        message: "Please input your state.",
                      },
                    ]}
                    className={css.inputAddress}
                  >
                    <Input placeholder="State" />
                  </Form.Item>
                  <Form.Item
                    name="shippingZip"
                    label="Zip / Postcode"
                    rules={[
                      {
                        required: false,
                        message: "Please input your zip or postalcode.",
                      },
                    ]}
                    className={css.inputAddress}
                  >
                    <Input placeholder="Zip / Postcode" />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                name="receiverNameSelect"
                label="Select Receiver"
                className={css.shippingSelect}
              >
                <Select
                  placeholder="Please select a receiver"
                  onChange={onReceiverChange}
                  allowClear
                >
                  {companyReceiver &&
                    companyReceiver.length > 0 &&
                    companyReceiver.map((receiver, index) => (
                      <Option key={index} value={receiver.id}>
                        {receiver.receiverFirstName} {receiver.receiverLastName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="receiverName"
                label="Receiver Name"
                rules={[
                  {
                    required: false,
                    message: "Please input your address.",
                  },
                ]}
                className={css.inputAddress}
              >
                {/* <Input
                  placeholder="Receiver Name"
                  value={receiverName.receiverLastName}
                  onChange={(e) => setReceiverName(e.target.value)} // Update state on change
                /> */}
                <Input placeholder="Receiver Name" />
              </Form.Item>
            </Card>
            {/* </Form> */}
          </Form.Item>

          <UploadFile
            formSubmitted={formSubmitted}
            user={user}
            companyId={companyId}
            orderId={orderId}
            onImageChange={handleImageChange}
          />

          <Form.Item>
            <Button type="primary" htmlType="submit" className={css.submitBtn}>
              Submit Order
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Footer />
    </>
  );
};

export default OrderForm;
