import React, { useContext, useEffect, useRef, useState } from "react";
import AppHeader from "../AppHeader/AppHeader";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  Card,
  List,
  Typography,
  Dropdown,
  Menu,
  Select,
  Space,
  Tag,
} from "antd";
import css from "./Profile.module.css";
import { db, auth } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import Login from "../LoginForm/LoginForm";

import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const Profile = () => {
  const [user, setUser] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [companyAddresses, setCompanyAddresses] = useState(null);

  const [dataSource, setDataSource] = useState([
    {
      key: "0",
      name: "Edward King",
      age: "32",
      address: "London, Park Lane no. 0",
    },
    {
      key: "1",
      name: "Edward King",
      age: "32",
      address: "London, Park Lane no. 1",
    },
  ]);

  const [count, setCount] = useState(2);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const defaultColumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "70%",
      editable: true,
    },

    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <a href="/#" style={{ color: "red" }}>
              Delete
            </a>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: count,
      name: `Edward King`,
      age: "32",
      address: `London, Park Lane no. ${count}`,
      number: `${count}`,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  // Import firebase company/user info

  const getUserInfo = async () => {
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const companyId = docSnap.data().companyId;
      setCompanyId(companyId);
    } else {
      console.log("No such document!");
    }
  };

  const fetchCompanyAddresses = async () => {
    const q = query(
      collection(db, "company-addresses"),
      where("companyId", "==", companyId)
    );

    const querySnapshot = await getDocs(q);
    const addresses = querySnapshot.docs.map((doc) => doc.data());

    setCompanyAddresses(addresses);
  };

  const fetchPurchaserInfo = async () => {
    const q = query(
      collection(db, "company-addresses"),
      where("companyId", "==", companyId)
    );

    const querySnapshot = await getDocs(q);
    const addresses = querySnapshot.docs.map((doc) => doc.data());

    setCompanyAddresses(addresses);
  };

  // paused

  // useEffect(() => {
  //   const authObserver = onAuthStateChanged(auth, (user) => {
  //     setUser(user);
  //     setLoading(false);
  //   });
  //   return authObserver;
  // }, []);

  // useEffect(() => {
  //   if (user) {
  //     getUserInfo();
  //     fetchCompanyAddresses();
  //   }
  // }, [user, getUserInfo, fetchCompanyAddresses]);

  // useEffect(() => {
  //   if (companyId) {
  //     fetchCompanyAddresses();
  //   }
  // }, [companyId, fetchCompanyAddresses]);

  if (!user) {
    return (
      <div>
        <Login />
      </div>
    );
  }
  return (
    <>
      <AppHeader />
      <div className={css.body}>
        <div className={css.profileInfo}>
          {/* test A */}

          {/* test B */}
          <Card
            title="Purchaser Info"
            bordered={true}
            className={css.antdCardStyle}
            headStyle={{ backgroundColor: "#f5f5f5" }}
            bodyStyle={{ padding: "16px" }}
          >
            <Button
              onClick={handleAdd}
              type="primary"
              style={{
                marginBottom: 16,
              }}
            >
              Add a new Purchaser
            </Button>
            <Table
              components={components}
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columns}
            />
          </Card>
        </div>
      </div>
    </>
  );
};
export default Profile;
