import { BrowserRouter, Route, Routes } from "react-router-dom";
import OrderForm from "./components/OrderForm/OrderForm";

import Register from "./components/Register/Register";
import OrderHistory from "./components/OrderHistory/OrderHistory";
import Profile from "./components/Profile/Profile";
import LandingPage from "./components/LandingPage/LandingPage";
import LoginForm from "./components/LoginForm/LoginForm";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const App = () => {
  const REACT_APP_RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/orderForm" element={<OrderForm />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/login"
          element={
            <GoogleReCaptchaProvider
              reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}
            >
              <LoginForm />
            </GoogleReCaptchaProvider>
          }
        />
        <Route path="/orderHistory" element={<OrderHistory />} />
        <Route path="/profile" element={<Profile />} />

        <Route path="/" element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
