import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Table,
  Descriptions,
  Divider,
  Typography,
  Spin,
  Button,
  Tag,
  Modal,
} from "antd";
import AppHeader from "../AppHeader/AppHeader";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db, storage } from "../../firebase/firebase";
import css from "./OrderHistory.module.css";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { ref, listAll, getDownloadURL } from "firebase/storage";

const { Title } = Typography;

const columns = [
  {
    title: "Order Date",
    dataIndex: "date",
    key: "date",
    defaultSortOrder: "descend",
    sorter: (a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      console.log("Comparing:", a.date, b.date, dateA, dateB); // Debugging

      return dateA - dateB;
    },
  },
  {
    title: "Order ID",
    dataIndex: "orderId",
    key: "orderId",
  },
  {
    title: "Purchaser Name",
    dataIndex: "purchaserName",
    key: "purchaserName",
  },

  {
    title: "Due Date",
    dataIndex: "dueDate",
    key: "dueDate",
    sorter: (a, b) => {
      // const dateA = new Date(a.dueDate);
      // const dateB = new Date(b.dueDate);

      const dateA = a.timeStamp;
      const dateB = b.timeStamp;

      return dateA - dateB;
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => {
      let color = status === "completed" ? "blue" : "volcano";
      return <Tag color={color}>{status.toUpperCase()}</Tag>;
    },
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
];

const OrderHistory = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState(null);
  const [companyOrders, setCompanyOrders] = useState([]);
  const [sampleImageUrls, setSampleImageUrls] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");

  const getUserInfo = async () => {
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const companyId = docSnap.data().companyId;
      setCompanyId(companyId);
      if (companyId === undefined) {
        setLoading(false);
      }
    } else {
      console.log("No such document!");
    }
  };

  const navigate = useNavigate();

  const showModal = (url) => {
    setCurrentImageUrl(url);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchCompanyOrders = useCallback(async () => {
    const q = query(
      collection(db, "orders"),
      where("companyId", "==", companyId)
    );

    const querySnapshot = await getDocs(q);
    setLoading(false);

    const orders = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    const transformedData = orders.map((order, index) => {
      const dateObj = new Date(order.deadlineDate);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      let formattedDate = `${
        monthNames[dateObj.getMonth()]
      } ${dateObj.getDate()}, ${dateObj.getFullYear()}`;

      const timestampToDate = (timestamp) => {
        const dateObj = new Date(timestamp * 1000);
        return `${
          monthNames[dateObj.getMonth()]
        } ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
      };

      function convertTimestampSecToLocalTime(timestampSec) {
        const date = new Date(timestampSec * 1000); // Convert to milliseconds
        return date.toLocaleString();
      }

      formattedDate = order.deadlineDate ? formattedDate : "No Due Date";

      return {
        key: index.toString(),
        date: timestampToDate(order.timestamp.seconds),
        dateTime: convertTimestampSecToLocalTime(order.timestamp.seconds),
        timeStamp: order.timestamp.seconds,
        status: order.status || "in progress",
        addressInfo: `${order.streetAddress}, ${order.secondAddress}, ${order.city}, ${order.state}, ${order.zip}`,
        purchaserName: order.purchaserInfo,
        dueDate: formattedDate,
        orderId: order.id,
        quantity: order.quantity,
        companyName: order.companyName,
        department: order.department,
        division: order.division,
        japaneseName: order.japaneseName,
        firstName: order.firstName,
        lastName: order.lastName,
        notes: order.notes,
        phoneNumber: order.phoneNumber,
        title: order.title,
        orderNumber: order.orderNumber,
        shippingMethod: order.shippingMethod,
        shippingInfo: order.shippingAddress
          ? `${order.shippingAddress}, ${order.shippingSecondAddress}, ${order.shippingCity}, ${order.shippingState}, ${order.shippingZip}`
          : "",
      };
    });

    setCompanyOrders(transformedData);
  }, [setCompanyOrders, companyId]);

  const fetchAllSampleImages = async (companyOrders, companyId) => {
    const orderImageMap = {};

    const orderPromises = companyOrders.map(async (order) => {
      const listRef = ref(
        storage,
        `companies/${companyId}/orders/${order.orderId}/sampleImage`
      );
      try {
        const res = await listAll(listRef);
        const urlPromises = res.items.map((itemRef) => getDownloadURL(itemRef));
        const urls = await Promise.all(urlPromises);
        orderImageMap[order.orderId] = urls;
      } catch (error) {
        console.error(
          `Error fetching images for order ${order.orderId}:`,
          error
        );
        orderImageMap[order.orderId] = [];
      }
    });
    await Promise.all(orderPromises);
    setSampleImageUrls(orderImageMap);
  };

  const expandedRowContent = (record) => {
    function formatPhoneNumber(phoneNumberString) {
      const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `${match[1]}-${match[2]}-${match[3]}`;
      }
      return null;
    }

    const formattedPhone = formatPhoneNumber(record.phoneNumber);

    return (
      <div style={{ padding: "16px", background: "#f7f7f7" }}>
        <div style={{ textAlign: "right" }}>
          {sampleImageUrls[record.orderId] &&
          sampleImageUrls[record.orderId].length > 0 ? (
            sampleImageUrls[record.orderId].map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Order ${record.orderId} - Item ${index + 1}`}
                style={{
                  width: "200px",
                  height: "200px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => showModal(url)}
              />
            ))
          ) : (
            <></>
          )}
          <Modal open={isModalVisible} footer={null} onCancel={handleCancel}>
            <img
              src={currentImageUrl}
              alt="Enlarged"
              style={{ width: "100%" }}
            />
          </Modal>
        </div>
        <Title level={4}>Order Details</Title>

        <Divider />
        <Descriptions column={1} bordered size="small">
          {record.firstName && (
            <Descriptions.Item label="First Name">
              {record.firstName}
            </Descriptions.Item>
          )}

          {record.lastName && (
            <Descriptions.Item label="Last Name">
              {record.lastName}
            </Descriptions.Item>
          )}
          {record.japaneseName && (
            <Descriptions.Item label="Japanese Name">
              {record.japaneseName}
            </Descriptions.Item>
          )}
          {record.title && (
            <Descriptions.Item label="Title">{record.title}</Descriptions.Item>
          )}
          {record.division && (
            <Descriptions.Item label="Division">
              {record.division}
            </Descriptions.Item>
          )}
          {record.department && (
            <Descriptions.Item label="Department">
              {record.department}
            </Descriptions.Item>
          )}
          {record.companyName && (
            <Descriptions.Item label="Company Name">
              {record.companyName}
            </Descriptions.Item>
          )}
          {record.addressInfo && (
            <Descriptions.Item label="Address Information">
              {record.addressInfo}
            </Descriptions.Item>
          )}
          {record.orderNumber && (
            <Descriptions.Item label="Purchaser's Order Number">
              {record.orderNumber}
            </Descriptions.Item>
          )}
          {formattedPhone && (
            <Descriptions.Item label="Phone Number">
              {formattedPhone}
            </Descriptions.Item>
          )}
          {record.dateTime && (
            <Descriptions.Item label="Ordered Time">
              {record.dateTime}
            </Descriptions.Item>
          )}
          {record.dueDate && (
            <Descriptions.Item label="Deadline Date">
              {record.dueDate}
            </Descriptions.Item>
          )}

          {record.quantity && (
            <Descriptions.Item label="Quantity">
              {record.quantity}
            </Descriptions.Item>
          )}
          {record.shippingMethod && (
            <Descriptions.Item label="Shipping Method">
              {record.shippingMethod}
            </Descriptions.Item>
          )}
          {record.shippingInfo && (
            <Descriptions.Item label="Shipping Information">
              {record.shippingInfo}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Notes">{record.notes}</Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  useEffect(() => {
    const authObserver = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return authObserver;
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (user) {
      getUserInfo();
    }
  }, [user]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (companyId) {
      fetchCompanyOrders();
    }
  }, [companyId, fetchCompanyOrders]);

  useEffect(() => {
    if (companyOrders) {
      fetchAllSampleImages(companyOrders, companyId);
    }
  }, [companyId, companyOrders]);

  if (loading) {
    return (
      <div className={css.spinScreen}>
        <Spin size="large" />
      </div>
    );
  }

  if (!user) {
    navigate("/login");
    return null;
  }

  return (
    <>
      <AppHeader />
      <div className={css.background}>
        <div className={css.tableStyles}>
          <Card
            title="Order History"
            // style={{ width: "90%", maxWidth: "1000px" }}
            className={css.antdCardStyle}
          >
            <Table
              columns={columns}
              dataSource={companyOrders}
              expandedRowRender={expandedRowContent}
            />
          </Card>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderHistory;
