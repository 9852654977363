function Agreement() {
  return (
    <div className="registration-agreement">
      <strong>User Registration Agreement for Hikaritype</strong>

      <p>
        This User Registration Agreement ("Agreement") is made between you
        ("User" or "you") and Hikaritype ("Company", "we", "our", or "us"). By
        registering for an account or using our services, you agree to be bound
        by the terms of this Agreement.
      </p>

      <strong>1. Registration Information and Account Security</strong>
      <p>
        1.1. When registering for an account, you must provide accurate,
        complete, and current information as requested on the registration page.
      </p>
      <p>
        1.2. You are responsible for maintaining the confidentiality of your
        login credentials and for any activities that occur under your account.
        Notify us immediately if you believe your account has been compromised.
      </p>

      <strong>2. Use of Services</strong>
      <p>
        2.1. You agree to use our services for lawful purposes only and in a
        manner consistent with the intended purpose of such services.
      </p>
      <p>
        2.2. We reserve the right to suspend or terminate your account and
        access to our services if we believe you are using the services in a way
        that violates this Agreement or any applicable laws.
      </p>

      <p>
        <strong>Acknowledgment</strong>
      </p>
      <p>
        By clicking on the "Register" button, you acknowledge that you have
        read, understood, and agree to be bound by the terms of this User
        Registration Agreement.
      </p>
      <strong>3. Privacy</strong>
      <p>
        3.1. Your registration and use of our services are subject to our
        Privacy Policy, which is incorporated by reference into this Agreement.
      </p>

      <strong>4. Intellectual Property</strong>
      <p>
        4.1. All content provided by Hikaritype, including but not limited to
        text, graphics, logos, and software, is the property of the Company or
        its licensors and is protected by copyright and other intellectual
        property laws.
      </p>

      <strong>5. Limitation of Liability</strong>
      <p>
        5.1. To the extent permitted by law, Hikaritype shall not be liable for
        any indirect, incidental, special, or consequential damages resulting
        from your use or inability to use our services or for any unauthorized
        access to or use of our servers.
      </p>

      <strong>6. Termination</strong>
      <p>
        6.1. We reserve the right to terminate your account at any time for any
        reason, including but not limited to violations of this Agreement.
      </p>

      <strong>7. Changes to this Agreement</strong>
      <p>
        7.1. We may modify this Agreement from time to time. Any changes will be
        effective when posted on our website or otherwise made available to you.
        Your continued use of the services after any changes are posted will
        constitute your acceptance of those changes.
      </p>

      <strong>8. Governing Law</strong>
      <p>
        8.1. This Agreement is governed by the laws of [Country/State, e.g.,
        "California, United States"], without regard to its conflict of law
        provisions.
      </p>

      <strong>9. Dispute Resolution</strong>
      <p>
        9.1. Any dispute arising out of or in connection with this Agreement
        shall be resolved through amicable negotiations. If negotiations fail,
        the dispute shall be submitted to [a competent court in Country/State or
        alternative dispute resolution method, e.g., "arbitration in California,
        United States"].
      </p>

      <strong>10. Miscellaneous</strong>
      <p>
        10.1. This Agreement constitutes the entire agreement between you and
        Hikaritype regarding your registration and use of our services.
      </p>
      <p>
        10.2. If any provision of this Agreement is deemed invalid, the rest of
        the Agreement shall remain in full force and effect.
      </p>
    </div>
  );
}

export default Agreement;
