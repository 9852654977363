import React, { useState } from "react";
import { Button } from "antd";
import { sendResetEmail } from "../../firebase/firebase";

const ForgotPasswordModal = () => {
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [resetEmailSuccess, setResetEmailSuccess] = useState(false);

  const handlePasswordReset = async () => {
    const response = await sendResetEmail(email);
    if (response.success) {
      console.log("hit");
      setResetEmailSuccess(true);
    } else {
      // handle error
    }
  };

  // auth
  //   .sendPasswordResetEmail(email)
  //   .then(() => {
  //     // Email sent successfully
  //     alert("Password reset email sent. Check your inbox!");
  //   })
  //   .catch((error) => {
  //     // Handle errors here
  //     console.error("Error sending password reset email:", error);
  //     alert(error.message);
  //   });

  // const handleOk = () => {
  //   auth
  //     .getUserByEmail(email)
  //     .then((userRecord) => {
  //       // User exists
  //       console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
  //       handlePasswordReset(email)
  //         .then(() => {
  //           message.success(
  //             "Password reset email sent. Please check your inbox."
  //           );
  //         })
  //         .catch(() => {
  //           message.error(
  //             "Failed to send password reset email. Please try again."
  //           );
  //         });
  //     })
  //     .catch((error: any) => {
  //       if (error.code === "auth/user-not-found") {
  //         // User does not exist
  //         console.log("User not found");
  //         message.error("No user found with this email.");
  //       } else {
  //         // Some other error
  //         console.error(error);
  //         message.error("Error occurred while checking the email.");
  //       }
  //     });

  //   setVisible(false);
  // };

  return (
    <>
      <Button type="link" onClick={() => setVisible(true)}>
        Forgot Password?
      </Button>
      {/* <Modal
        title="Forgot Password"
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        okText="Submit"
      >
        <Form>
          <Form.Item
            label="Email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </Form.Item>
        </Form>
      </Modal> */}
    </>
  );
};

export default ForgotPasswordModal;
