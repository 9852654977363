import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword, db } from "../../firebase/firebase";
import { Form, Input, Button, Card, Spin, Alert, Modal } from "antd";
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";
import css from "./LoginForm.module.css";
import { collection, query, where, getDocs } from "firebase/firestore";
import ForgotPasswordModal from "./ForgotPassword";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [passVal, setPassVal] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const inputRef = useRef(null);
  const passRef = useRef(null);

  const navigate = useNavigate();

  const handleUsernameChange = async (event) => {
    setUsername(event.target.value);

    await getUserId();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (inputRef.current && inputRef.current.input) {
        const currentInputValue = inputRef.current.input.value;
        if (currentInputValue !== inputValue) {
          setInputValue(currentInputValue);
        }
      }
      if (passRef.current && passRef.current.input) {
        const currentPassVal = passRef.current.input.value;
        if (currentPassVal !== passVal) {
          setPassVal(currentPassVal);
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [inputValue, passVal]);

  const getUserId = useCallback(async () => {
    const q = query(collection(db, "users"), where("username", "==", username));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();

      setCompanyId(data.companyId);
      setEmail(data.email);
    });
  }, [username, setCompanyId, setEmail]);

  const showError = () => {
    Modal.error({
      title: "Login Failed",
      content: "Invalid username or password. Please try again.",
    });
  };

  const signIn = async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const currentInputValue = inputRef.current.input.value;
    const currentPassVal = passRef.current.input.value;

    setUsername(currentInputValue);
    setPassword(currentPassVal);

    const token = await executeRecaptcha("signin");

    try {
      const payload = {
        token: token,
      };

      const response = await fetch(
        "http://api.whitechrome.io/verify-recaptcha",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (data.success) {
        if (username) {
          await getUserId();
        }

        if (username.includes("@")) {
          await signInWithEmailAndPassword(auth, username, password);
        } else {
          await signInWithEmailAndPassword(auth, email, password);
        }
        await fetchCompanyAddresses();
        navigate("/orderForm");

        if (rememberMe) {
          localStorage.setItem("rememberedUsername", username);
        } else {
          localStorage.removeItem("rememberedUsername");
        }
      } else {
        console.log("data fail");
      }
    } catch (error) {
      showError();
    }
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const fetchCompanyAddresses = async () => {
    const q = query(
      collection(db, "company-addresses"),
      where("companyId", "==", companyId)
    );

    const querySnapshot = await getDocs(q);
    const addresses = querySnapshot.docs.map((doc) => doc.data());
  };

  useEffect(() => {
    if (username) {
      getUserId();
    }
  }, [username, getUserId]);

  useEffect(() => {
    const savedUsername = localStorage.getItem("rememberedUsername");
    if (savedUsername) {
      setUsername(savedUsername);
    }
  }, []);

  const onFinishFailed = (errorInfo) => {
    setHasError(true);
  };

  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <section className="hero">
      <Card title="Login" className={css.loginCard}>
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={signIn}
          onFinishFailed={onFinishFailed}
        >
          {hasError && (
            <Alert
              message="There was an issue with your login"
              type="error"
              showIcon
              className="mb-3"
            />
          )}
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input
              ref={inputRef}
              prefix={<UserOutlined className={css.siteFormItemIcon} />}
              onChange={handleUsernameChange}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              ref={passRef}
              prefix={<LockOutlined className={css.siteFormItemIcon} />}
              onChange={handlePasswordChange}
            />
          </Form.Item>

          <Form.Item>
            <Form.Item
              name="remember"
              valuePropName="checked"
              className={css.rememberContainer}
              checked={rememberMe}
              onChange={handleRememberMeChange}
            >
              <p>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  icon={isLoading ? <Spin indicator={loadingIcon} /> : null}
                  disabled={isLoading}
                  style={{
                    background: "#4154f1",
                    borderRadius: "4px",
                    color: "#fff",
                  }}
                >
                  Submit
                </Button>

                <div className={css.loginFormForgot}>
                  <ForgotPasswordModal />
                </div>
              </p>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <p className={css.registerContainer}>
              Or{" "}
              <a href="/register" className={css.registerLink}>
                {" "}
                register now!
              </a>
            </p>
          </Form.Item>
        </Form>
      </Card>
    </section>
  );
};

export default LoginForm;
